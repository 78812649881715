export default {
  default: [
    {
      title: "Analytic",
      description:
        "The audience measurement services used to generate useful statistics attendance to improve the site.",
      services: [
        {
          name: "google-analytics",
          title: "Google Analytics",
          cookies: [
            "_ga",
            "_gat",
            "_gid",
            "__utma",
            "__utmb",
            "__utmc",
            "__utmt",
            "__utmz",
          ],
          consentOptions: [
            {
              name: "ad_storage",
              title: "Ads Storage",
              description:
                "Enables storage of advertising-related data like conversion measurement and remarketing",
            },
            {
              name: "ad_user_data",
              title: "Ads User Data",
              description:
                "Allows collection and processing of user data for advertising purposes",
            },
            {
              name: "ad_personalization",
              title: "Ads Personalization",
              description:
                "Enables personalized advertising based on user behavior and interests",
            },
            {
              name: "analytics_storage",
              title: "Analytics Storage",
              description:
                "Enables storage of analytics data to measure site usage and performance",
            },
          ],
          description:
            "Analytics cookies, from the Google third party, are intended to collect statistics of anonymous visits which, once cross-checked, could be intrusive for the privacy of users of the site. As a result, the use of Google Analytics tracers is disabled by default on this site. You can authorize them to allow the person in charge of the site to improve the quality of the navigation and the information provided on this site.",
          link: "https://support.google.com/analytics/answer/6004245",
        },
      ],
    },
  ],
};
